<template>
  <v-dialog
    v-model="dialogTentacle"
    width="85%"
    max-width="800px"
  >
    <template
      v-slot:activator="{ on, attrs }"
    >
      <v-btn
        class="ml-2"
        color="info"
        v-bind="attrs"
        v-on="on"
        @click="botaoChaveTentacle()"
      >
        Chave Tentacle
        <v-divider
          class="mx-4"
          vertical
        />
        <v-icon>mdi-hammer-wrench</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-toolbar
        dark
        color="info"
      >
        <v-btn
          class="justify-center"
          icon
          @click="dialogTentacle = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Configuração Tentacle</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <div
          v-for="(file, index) in configFILE"
          :key="index"
        >
          <v-alert
            outlined
            prominent
            color="info"
            text
            style="
              overflow: hidden;
            "
          >
            <h4>
              {{ selectedNames[index].name }}
              <v-icon
                @click="copyText(index)"
              >
                mdi-content-copy
              </v-icon>
            </h4>
            <span
              :key="index"
              style="
                overflow-wrap: break-word;
                overflow: hidden;
                width: fit-content;
                max-width: 700px;
                display: block !important;
              "
            >
              {{ file }}
            </span>
          </v-alert>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapActions } from 'vuex'

  export default {
    name: 'ConfigTentacle',
    model: {
      prop: 'selectedNames',
      event: 'update:selectedNames',
    },
    props: {
      selectedNames: {
        type: Array,
        required: true,
      },
    },
    data: () => {
      return {
        configFILE: '',
        dialogTentacle: false,
      }
    },
    methods: {
      ...mapActions('customers', ['getTentacleConfigCredential']),
      async botaoChaveTentacle () {
        const keysTentacle = this.selectedNames.map(user => {
          return this.getTentacleConfigCredential(user._id)
        })
        this.configFILE = await Promise.all(keysTentacle)
      },
      copyText (id) {
        navigator.clipboard.writeText(this.configFILE[id])
        this.$alert('success', 'Copiado com sucesso')
      },
    },
  }
</script>
